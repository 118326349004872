/* Google fonts */
/*@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,500;1,400&display=swap');*/

/* Helpers & Reset */
:root {
    /* --c-brand: #fac562;*/
    --c-brand: #D35100;
    --c-dark: #333;
    --c-body: #666;
    --c-light: #f8f8f8;
    --f-base: "Plus Jakarta Sans", sans-serif;
    --n-height: 100px;
    --primaryHighlight: #D35100; /* Main highlight: burnt orange */
    --secondaryHighlight: #F9B122; /* Lightning Yellow */
}

*, *::after, *::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    color: var(--bs-gray-500);
    background-color: var(--c-dark);
    font-family: var(--f-base);
    line-height: 1.8;
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--bs-gray-500);
    line-height: 1.2;
    margin-bottom: 10px;
    font-weight: 400;
}

.heading-xl {
    font-size: clamp(44px, 10vw, 100px);
    text-transform: uppercase;
}

.heading-1 {
    font-size: clamp(32px, 6vw, 54px);
}

.heading-2 {
    font-size: clamp(28px, 4vw, 36px);
}

.heading-3 {
    font-size: 20px;
}

a {
    color: var(--c-dark);
    text-decoration: none;
    transition: all 0.4s ease-in-out;
}

    a:hover {
        color: var(--c-brand);
    }

img {
    max-width: 100%;
    width: 100%;
    height: auto;
}

ul {
    list-style: none;
    padding-left: 0;
}

section {
    padding: 140px 0;
}

.container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
}

.btn {
    display: inline-block;
    /*background-color: transparent;*/
    background-color: var(--c-brand);
    color: #2e3032;
    border: 1px solid var(--c-brand);
    font-family: var(--f-base);
    padding: 16px 34px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    font-size: 14px;
}

    .btn:hover {
        background-color: var(--secondaryHighlight);
        color: white;
    }

/* Navbar */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--n-height);
    display: flex;
    z-index: 999;
}

    .navbar .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

.container.language-selector {
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
}

.brand {
    color: var(--bs-gray-500);
    font-size: 28px;
}



.nav-links {
    display: flex;
}

    .nav-links li:not(:last-child) {
        margin-right: 44px;
    }

    .nav-links li.active a {
        box-shadow: 0 2px var(--c-brand);
    }

    .nav-links li a {
        color: var(--bs-gray-500);
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

        .nav-links li a:hover {
            color: var(--c-brand);
        }

.hamburger {
    display: block;
    cursor: pointer;
    display: none;
}

    .hamburger span {
        display: block;
        width: 34px;
        height: 2px;
        background-color: white;
        margin: 8px auto;
        transition: all 0.4s ease;
    }

    .hamburger.active span:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
    }

    .hamburger.active span:nth-child(2) {
        transform: translateY(-5px) rotate(-45deg);
    }

.scrolled {
    background-color: var(--c-dark);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition-duration: 1s;
}

    .scrolled .brand {
        color: var(--c-light);
    }

    .scrolled .nav-links li a {
        color: var(--bs-gray-500);
    }

        .scrolled .nav-links li a:hover {
            color: var(--c-brand);
        }

    .scrolled .brand:hover {
        color: var(--c-brand);
    }

    .scrolled .hamburger span {
        background-color: var(--c-light);
    }

@media (max-width: 768px) {
    .nav-links {
        position: fixed;
        top: var(--n-height);
        left: 0;
        width: 100%;
        flex-direction: column;
        padding: 0 24px;
        transition: all 0.3s ease-in-out;
        height: 0;
        overflow: hidden;
        background-color: white;
        justify-content: center;
    }

        .nav-links li a {
            color: var(--c-dark);
        }

        .nav-links.active {
            height: 200px;
        }

        .nav-links li {
            padding: 6px 0;
        }

    .hamburger {
        display: block;
    }
}

/* Hero */
#hero {
    min-height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(images/oak_orange_sky.jpg);
    background-position: center;
    background-size: cover;
    display: grid;
    place-content: center;
    text-align: center;
}

    #hero h1 {
        color: var(--bs-gray-400);
    }

@keyframes scrolltodown {
    0% {
        transform: translateY(30%);
        opacity: 0;
    }

    50% {
        transform: translateY(-30%);
        opacity: 1;
    }

    100% {
        transform: translateY(60%);
        opacity: 0;
    }
}

.scroll-to-down {
    width: 30px;
    height: 60px;
    margin-left: -15px;
    display: grid;
    place-content: center;
    font-size: 24px;
    color: white;
    border: 2px solid rgba(255,255,255, 0.2);
    border-radius: 100px;
    position: absolute;
    bottom: 48px;
    left: 50%;
}

    .scroll-to-down .las {
        animation: scrolltodown 2s ease infinite;
    }

/* Section title */
.section-intro {
    margin-bottom: 44px;
}

/* Grid*/
.grid {
    display: grid;
    gap: 44px;
}

.two-col-grid {
    grid-template-columns: repeat(2, 1fr);
}

.three-col-grid {
    grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 768px) {

    .grid {
        gap: 24px;
    }

    .two-col-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .three-col-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}


/* Service */
#services .grid {
    gap: 0;
}

.service:nth-child(odd) {
    background-color: #2e3032; /* var(--bs-dark);*/
}

.service {
    padding: 44px;
}

    .service p {
        font-size: 14px;
    }

    .service .icon {
        font-size: 38px;
        color: var(--c-brand);
    }

/* Project */
.project {
    position: relative;
    overflow: hidden;
}

    .project img {
        height: 100%;
        object-fit: cover;
        transition: all 0.4s ease-in-out;
    }

    .project .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 44px;
        display: flex;
        align-items: flex-end;
        transition: all 0.4s ease-in-out;
        opacity: 0;
    }

        .project .overlay h2 {
            color: white;
        }

        .project .overlay p {
            color: rgba(255,255,255, 0.6);
        }

    .project:hover .overlay {
        opacity: 1;
        display: flex;
    }

    .project:hover img {
        transform: rotate(15deg) scale(1.5);
    }

/* Blog */
#blog {
    background-color: var(--c-light);
}

.blog-post h3 {
    margin-top: 16px;
}

/* contact */
/*#contact .grid {
    grid-template-columns: 2.5fr 1fr;
}*/

@media (max-width: 768px) {
    #contact .grid {
        grid-template-columns: 1fr;
    }
}

form {
    width: 100%;
}

.contact-widget {
    margin-bottom: 44px;
}

.form-group {
    margin-bottom: 24px;
}

    .form-group .form-input {
        display: block;
        width: 100%;
        padding: 16px 0;
        border-radius: 0;
        border: none;
        font-family: var(--f-base);
        border-bottom: 1px solid var(--bs-gray-800);
    }

        .form-group .form-input:focus {
            outline: none;
        }

.form-input {
    background-color: var(--c-dark);
    color: var(--bs-gray-500);
}

/* Footer */
footer {
    padding: 60px 0;
    background-color: var(--c-dark);
}

    footer .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    footer p,
    footer a {
        color: white;
    }

    footer .social-icons a {
        font-size: 24px;
    }

        footer .social-icons a:not(:last-child) {
            margin-right: 24px;
        }





/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
}

.section-bg {
    background-color: #f7fbfe;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

    .section-title h2 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
    }

        .section-title h2::before {
            content: "";
            position: absolute;
            display: block;
            width: 120px;
            height: 1px;
            background: #ddd;
            bottom: 1px;
            left: calc(50% - 60px);
        }

        .section-title h2::after {
            content: "";
            position: absolute;
            display: block;
            width: 40px;
            height: 3px;
            background: var(--c-brand);
            bottom: 0;
            left: calc(50% - 20px);
        }

    .section-title p {
        margin-bottom: 0;
    }


/*--------------------------------------------------------------
# What We Do
--------------------------------------------------------------*/
.what-we-do .icon-box {
    text-align: center;
    padding: 30px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
}

    .what-we-do .icon-box .icon {
        margin: 0 auto;
        width: 64px;
        height: 64px;
        background: #eaf4fb;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        transition: ease-in-out 0.3s;
    }

        .what-we-do .icon-box .icon i {
            color: var(--c-brand);
            font-size: 28px;
        }

    .what-we-do .icon-box h4 {
        font-weight: 700;
        margin-bottom: 15px;
        font-size: 24px;
    }

        .what-we-do .icon-box h4 a {
            color: #384046;
            transition: ease-in-out 0.3s;
        }

    .what-we-do .icon-box p {
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 0;
    }

    .what-we-do .icon-box:hover {
        border-color: #fff;
        box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    }

        .what-we-do .icon-box:hover h4 a,
        .what-we-do .icon-box:hover .icon i {
            color: var(--c-brand);
        }


/*//// Notify fix for close button*/
button.close {
    box-sizing: content-box;
    padding: 0.25em 0.25em;
    color: #000;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
    background-color: transparent;
    font-size: x-large;
    height: 1em;
    width: 1em;    
}

.language-selector a {
    color: var(--bs-gray-500);
}

.language-selector a.active {
    box-shadow: 0 1px var(--c-brand);
}

.scrolled .language-selector a {
    color: var(--bs-gray-500);
}

/* Hero Section */
#hero .btn-get-started {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 2px;
    display: inline-block;
    padding: 12px 28px;
    border-radius: 4px;
    transition: ease-in-out 0.3s;
    color: #fff;
    background: var(--c-brand);
    text-transform: uppercase;
}

#hero h1 span {
    border-bottom: 4px solid var(--c-brand);
}

#hero h2 {
    color: var(--bs-gray-400);
}

#hero a:hover {
    background-color: var(--primaryHighlight);
}

.about .las {
    color: var(--c-brand);
    font-size:18px;
}

.contact-email-section {
    background-color: var(--c-light);
}

#anchor-contact, #anchor-about, #services-about {
    display: block;
    position: relative;
    top: -110px;
    visibility: hidden;
}

.dark-text {
    color: var(--c-dark);
}

a {
    color: var(--primaryHighlight);
}
    a:hover {
       color: var(--secondaryHighlight);
    }